import React, { useEffect, useState } from "react";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo.png";

function EnterOTP() {
  const navigate = useNavigate();
  const [otp, setOTP] = useState(null);
  const [email, setEmail] = useState("");

  //   HandleSubmit on OTP entry
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      toast.warn("You must enter OTP code");
      return;
    }
    console.log("EMAIL VALUE IS: " + email);
    console.log("OTP VALUE IS: " + otp);

    try {
      const response = await fetch(
        `${APIEnd}/forgot_password/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
          body: JSON.stringify({
            type: "otp",
            otp: otp,
            email: email,
          }),
        }
      );
      const result = await response.json();
      
      if(response.status === 400 && result.message){
        toast.warn(result.message)
      }else if(response.status === 200){
        navigate("/change-password")
      }else{
        toast.error("Sorry!!, Something went wrong on our side")
        navigate("/")
      }

      setOTP(null)
    } catch (error) {
      toast.error("Unknown Eror Try Later");
    }
  };

  useEffect(() => {
    setEmail(localStorage.getItem("resetEmail"));
  }, [email]);

  return (
    <div style={styles.container}>

      <form onSubmit={handleSubmit} style={styles.form}>
      <img
          src={logo}
          alt="Avisa"
          style={{
            display: "flex",
            justifySelf: "center",
            alignSelf: "center",
            width: 152,
            height: 150,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "8px" }} />
          <p style={{ color: "#345" }}>
            A code has been sent to the email previously entered, please enter
            code
          </p>
        </div>
        <div style={styles.formGroup}>
          <input
            id="otp"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
            style={styles.input}
            placeholder="Enter OTP"
          />
        </div>

        <button type="submit" style={styles.button}>
          Confirm
        </button>
      </form>
    </div>
  );
}

// Inline styles for basic styling
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#3a663a",
    padding: "20px",
  },
  form: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  formGroup: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "5px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#007bff",
    color: "#Green",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  error: {
    color: "red",
    fontSize: "0.875rem",
  },
  message: {
    color: "green",
    fontSize: "1rem",
    marginBottom: "20px",
  },
};

export default EnterOTP;
