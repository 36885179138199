import DataTable from "react-data-table-component";
import APIEnd, {API_KEY} from "../../configs/apiEndPoint";
import React, { useEffect, useState, useMemo } from "react";
import { useAuth } from "../../context/AuthContext";
import RefreshButton from "../Refreshbutton/RefreshButton";
import { toast } from "react-toastify";

const columns = [
  {
    name: "Referal Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Session",
    selector: (row) => row.session,
    sortable: true,
  },
  {
    name: "Date",
    selector: (row) => row.date.slice(0, 10),
    sortable: true,
  },
];

function Table() {
  const { token,  setToken, referals, setReferals, logout } = useAuth();

  const [referalList, setReferalList] = useState(null);

  const [records, setRecords] = useState([]);

  

  const getReferalList = async () => {
    try {
      const response = await fetch(`${APIEnd}/partner/referal/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data.detail) {
        toast.warn(data.detail)
        logout()
      } else {
        // Inside the else block of getReferalList function
        localStorage.setItem("referals", JSON.stringify(data));
        // console.log(data)
      }
    } catch (error) {
      console.log("ERROR GETTING INFORMATION ABOUT PARTNERS", error);
      logout()
    }
  };

  // gET rEFERALS lIST
  const getReferalListNEW = async () => {
    if (!referalList || referalList === undefined || referalList === null) {
      await getReferalList();
    }

    await getReferalList();

    if (localStorage.key("referals")) {
      const data = JSON.parse(localStorage.getItem("referals"));
      setReferalList(data);
      setRecords(data);
      setReferals(data)
    } else {
      console.log("NO REFERALS IN LOCAL STORAGE");
      getReferalList();
    }
  };

  // HANDLE sEARCH FILTER
  const handleFilter = async (e) => {
    // getReferalList();
    const testData = JSON.parse(localStorage.getItem("referals"));

    // console.log("THIS IS HANDLE FILTER", localStorage.getItem("referals"));

    const newData = testData?.filter((row) => {
      return row.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setRecords(newData);
    // console.log(newData);
  };

  useEffect(() => {
    getReferalListNEW();
  }, []);

  // console.log("THIS IS THE REFERAL LIST", "Context REF", referalList?.results)

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "2vw",
          marginLeft: "2vw",
        }}
      >
        <div>
          <p style={{ margin: 0, fontSize: 12, color: "#555" }}>Search</p>

          <input
            placeholder="Search"
            type="text"
            onChange={handleFilter}
            style={{
              height: 17,
              backgroundColor: "#e8eaea",
              fontWeight: "lighter",
              padding: 12,
              marginTop: 10,
            }}
          />
        </div>

        <RefreshButton
          onClick={() => {
            getReferalListNEW();
            toast.success("Referals Table Updated");
          }}
        />
      </div>
      <DataTable columns={columns} data={records} pagination fixedHeader />
    </div>
  );
}

export default Table;
