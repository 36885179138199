import React from "react";
import { useAuth } from "../../context/AuthContext";
import { useTable } from "react-table";
import "./table.css";

const columns = [
  { Header: "ID", accessor: "id" },
  { Header: "Account Name", accessor: "account_name" },
  { Header: "Account Number", accessor: "account_number" },
  { Header: "Status", accessor: "status" },
  { Header: "Request Date", accessor: "created_at" },
  { Header: "Payment Date", accessor: "modified_at" },
];

function WithdrawalTable({ data }) {
  const { token } = useAuth();
  let requestDate = data.created_at;
  let paymentDate = data.modified_at;
  
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default WithdrawalTable;
