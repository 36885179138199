import { RouteType } from "./config";

import ChangelogPage from "../pages/changelog/ChangelogPage";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InstallationPage from "../pages/installation/InstallationPage";
import DocumentationPage from "../pages/documentation/DocumentationPage";
import { DashboardCustomizeOutlined } from "@mui/icons-material";
import ForgotPassword from "../pages/passwordReset/ForgotPassword";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <InstallationPage />,
    state: "home",
  },
  {
    path: "/dashboard",
    element: <InstallationPage />,
    state: "Dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardCustomizeOutlined />,
    },
  },

  {
    path: "/promocode",
    element: <DocumentationPage />,
    state: "promocode",
    sidebarProps: {
      displayText: "Promo Code",
      icon: <ArticleOutlinedIcon />,
    },
  },
  {
    path: "/user-profile",
    element: <ChangelogPage />,
    state: "User Profile",
    sidebarProps: {
      displayText: "User Profile",
      icon: <FormatListBulletedOutlinedIcon />,
    },
  },
];

export default appRoutes;
