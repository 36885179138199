import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./redux/store";
import { UserContextProvider } from "./context/UserContext";
import { AuthProvider } from "./context/AuthContext";
import { VisibilityProvider } from "./context/VisibilityContext";

// font awa

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";


library.add(fas);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
      <CssBaseline />
      <AuthProvider>
        <UserContextProvider>
          <VisibilityProvider>
            <App />
          </VisibilityProvider>
        </UserContextProvider>
      </AuthProvider>
    </Provider>
);


