import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type Props = {};

const DocumentationPage = (props: Props) => {
  const [promoCode, setPromocode] = useState("");
  const { token, partnerInformation, logout } = useAuth();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    async function updatePromoCode() {
      if (!promoCode || promoCode.length < 3) {
        toast.warn("Unique license code must be 3 letters of more");
        return;
      }
      try {
        // console.log("THIS IS LOCALSTORE TOKEN:::", token);
        setLoading(true);
        const response = await fetch(`${APIEnd}/promo/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${API_KEY}`,
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ promo_code: promoCode }),
        });

        const data = await response.json();
        if (data.detail) {
          toast.error("Something went wrong try again");
          navigate("/Dashboard");
          window.location.reload();
        } else if (data.response) {
          toast.success("Referral Code Successfully changed!!!");
          navigate("/Dashboard");
        }
        return partnerInformation;
      } catch (error) {
        toast.error("ERROR GETTING INFORMATION ABOUT PARTNERS");
        logout();
      } finally {
        setLoading(false);
      }
    }
    updatePromoCode();
  };

  return (
    <div>
      
      <div
        style={{
          backgroundColor: "white",
          maxWidth: 400,
          padding: 8,
          borderRadius: 8,
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        <h1 style={{ display: "inline", fontSize:"1.5rem" }}>Change Unique License Code</h1>
        <p>
          Current License Code{" "}
          <span
            style={{ color: "lightgreen", fontWeight: "bold" }}
          >{`"${partnerInformation?.promo_code}"`}</span>{" "}
        </p>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <input
            value={promoCode}
            type="text"
            onChange={(e) => {
              setPromocode(e.target.value);
            }}
          />
          <button
            disabled={loading}
            onClick={() => handleClick()}
            style={{ backgroundColor: "green" }}
          >
            {loading ? "Loading":"Update" }
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentationPage;
