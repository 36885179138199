import React, { useState, useEffect } from "react";
import "./OverlayForm.css";
import { toast } from "react-toastify";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

function OverlayForm() {
  const { token } = useAuth();
  const navigate = useNavigate();

  const [accountName, setAccountName] = useState("")
  const [paymentMethod, setPaymentOption] = useState("");
  const [gatewayID, setGateWayID] = useState(0)
  const [phone, setPhone] = useState("")
  const [amount, setAmount] = useState("")
  const [gatewaysList, setGatewaysList] = useState([]);
  const [loading, setLoading] = useState(false)
    // Array of options for withTypes
  
  const [isOpen, setIsOpen] = useState(false);

  const openOverlay = () => {
    getGateways()
    setIsOpen(true)
  };
  const closeOverlay = () => setIsOpen(false);

  const handleSelectChange = (event) => {
    event.preventDefault()
    const selectedPaymentMethod = event.target.value
    setPaymentOption(selectedPaymentMethod);
    // const item = gatewaysList.find(item => item.name === selectedPaymentMethod)
    // const id = item.id
    // console.log("Item is ",item.id, "Gateway ID", gatewayID);
   
  };


  const getGateways = async () => {
    try {
      const response = await fetch(`${APIEnd}/withdraw?data=gateways`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        }
      });
      
      if (!response.ok) {
        toast.error("Error getting payment gateways.\nPlease try later")
        throw new Error(`Error: ${response.status}`);
      }
      
      const data = await response.json();
      setGatewaysList(data)
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data")
    }
  };
  

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // Basic validation
    const phoneNumber = Number(phone)
    if (!accountName ||  (phoneNumber < 600000000 || phoneNumber > 699999999 || !amount) || !gatewayID) {
      toast.warn("Invalid information. All fields are required!");
      Number(phone)
      setLoading(false)
      return;
    }
  
    try {
      const phoneNumber = Number(phone)
      const withDrawalAmount = Number(amount)
      const requestData = {
        account_name: accountName,
        account_number: phoneNumber,
        gateway: gatewayID,
        amount: withDrawalAmount
      }
      
      const response = await fetch(`${APIEnd}/withdraw/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify(requestData),
      });

      if (response.status === 400) {
       const resData = await response.json()
       toast.warn(resData.message)
       closeOverlay()
       return
      }
      
      if (!response.ok) {
        const errorData = await response.json() 
        toast.warn("Error sending withdrawal request.\nTry later!")
        return
      }
      
      const data = await response.json();
      toast.success(data.message);
      closeOverlay()
      navigate("/promocode");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit. Please try again later.");
    }finally{
      setLoading(false)
    }
  };

  useEffect(()=>{
    const item = gatewaysList.find(item => item.name === paymentMethod)
    if (item) {
      const id = item.id
      setGateWayID(id)
    }
  }, [gatewayID, gatewaysList, paymentMethod])
  

  return (
    <div>
      {/* Button to open the overlay */}
      <button onClick={openOverlay} className="open-button">
        Withdraw
      </button>

      {/* Overlay with form */}
      {isOpen && (
        <div className="overlay" >
          <div className="overlay-content" style={{display:"flex", flexDirection:"column"}}>
            <h2>Withdrawal Form</h2>
            <form >
                <div  > 
                    <span style={{fontSize: "1rem"}} >Account Name</span>
               
                <input type="text" name="name" value={accountName} onChange={(e)=>{
                  e.preventDefault()
                  setAccountName(e.target.value)

                }} required autoFocus/>
              </div>
             
             <div >
                <span style={{fontSize: "1rem"}}>Payment GateWay</span>
                <select  id="dynamic-options" value={paymentMethod} onChange={handleSelectChange} style={{marginBottom:"10px",padding: "9px", borderRadius: "8px", fontSize: "14px", borderColor:"#9b9898"}} >
                <option value="">Select a payment method</option>
                {gatewaysList.map((option, index)=>(
                    <option key={index} value={option.name}>
                        {option.name}
                    </option>
                ))}
                </select>

             </div >
              
              <div >
                <span style={{fontSize: "1rem"}}>Phone</span>
                <input name="phone" type="number" value={phone} onChange={(e)=>{
                  e.preventDefault()
                  setPhone(e.target.value)
                }} required />
             </div>
              <div >
                <span style={{fontSize: "1rem"}}>Amount</span>
                <input name="Amount" type="number" value={amount} onChange={(e)=>{
                  e.preventDefault()
                  setAmount(e.target.value)
                }} required />
             </div>
              
              <button type="submit" onClick={handleFormSubmit} disabled={loading} className="submit-button">{loading ? "Loading...": "Submit"}</button>
              <button type="button" onClick={closeOverlay} className="close-button">
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default OverlayForm;
