// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
input::placeholder {
    color: #444; /* Change the color to whatever you want */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/changelog/components/style.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,WAAW,EAAE,0CAA0C;EACzD","sourcesContent":["/* styles.css */\ninput::placeholder {\n    color: #444; /* Change the color to whatever you want */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
