import React, { useState } from "react";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo.png";
import "./login.css";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to validate email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  //   HandleSubmit to change email
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // Validate the email before submitting
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email.");
      toast.error("Email fields is required");
      setLoading(false)
      return;
    }
    localStorage.setItem("resetEmail", email);
    try {
      fetch(`${APIEnd}/forgot_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
        },
        body: JSON.stringify({
          type: "email",
          email: email,
        }),
      }).then((response) => {
        if (response.status === 400) {
          toast.warn(
            "OTP Code not sent, either email not found or there was an error"
          );
          return;
        } else if (response.status === 200) {
          navigate("/enter-otp");
        }
      });
    } catch (error) {
      toast.error("Unknown Eror Try Later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <img
          src={logo}
          alt="Avisa"
          style={{
            display: "flex",
            justifySelf: "center",
            alignSelf: "center",
            width: 152,
            height: 150,
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "8px" }} />
          <p style={{ color: "#345" }}>
            Enter the email address associated with your account
          </p>
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            placeholder="Enter your email"
          />
          {emailError && <p style={styles.error}>{emailError}</p>}
        </div>

        <button type="submit" style={styles.button}>
          {loading ? "loading" : "Confirm"}{" "}
        </button>
      </form>
    </div>
  );
}

// Inline styles for basic styling
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#3a663a",
    padding: "20px",
  },
  form: {
    width: "100%",
    maxWidth: "450px",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  formGroup: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "5px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  error: {
    color: "red",
    fontSize: "0.875rem",
  },
  message: {
    color: "green",
    fontSize: "1rem",
    marginBottom: "20px",
  },
};

export default ForgotPassword;
