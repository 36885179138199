import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useTable } from "react-table";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { toast } from "react-toastify";


import "./table.css";

const columns = [
  { Header: "ID", accessor: "id" },
  { Header: "Account Name", accessor: "account_name" },
  { Header: "Account Number", accessor: "account_number" },
  { Header: "Status", accessor: "status" },
  { Header: "Request Date", accessor: "created_at" },
  { Header: "Payment Date", accessor: "modified_at" },
];

function WithdrawalTable() {
  const { token ,  logout } = useAuth();
  const [data, setData] = useState([])

  const getWithdrawalHistory = async () => {
    try {
      const response = await fetch(`${APIEnd}/withdraw?data=withdrawals`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const data = await response.json()
        console.log("Error Withdrawal", data)
        throw new Error(`Error: ${response.status}`);
      }

      const resdata = await response.json();
      console.log("Withdrawal History: ", data);
      setData(resdata)
    } catch (error) {
      console.error("Error fetching Withdrawal data:", error);
      toast.error("Error fetching data");
      logout()
    }
  };
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

    useEffect(() => {
      getWithdrawalHistory()
    },[])

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default WithdrawalTable;
