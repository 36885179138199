import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import WithdrawalTable from "./WithdrawalTable";

type Props = {};

const DocumentationPage = (props: Props) => {
  const [promoCode, setPromocode] = useState("");
  const { token, setPartnerInformation, partnerInformation, referals, logout } = useAuth();
  const [data, setData] =useState([])

  const navigate = useNavigate();

  const handleClick = () => {
    async function updatePromoCode() {
      if (!promoCode || promoCode.length < 3) {
        toast.warn("Promo Code must be 3 letters of more");
        return;
      }
      try {
        // console.log("THIS IS LOCALSTORE TOKEN:::", token);

        const response = await fetch(`${APIEnd}/promo/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${API_KEY}`,
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ promo_code: promoCode }),
        });

        const data = await response.json();
        // console.log("New PROMO CODE", data);
        if (data.detail) {
          toast.error("Something went wrong try again");
          navigate("/Dashboard");
          window.location.reload();
        } else if (data.response) {
          toast.success("Referral Code Successfully changed!!!");
          navigate("/Dashboard");
        }
        return partnerInformation;
      } catch (error) {
        toast.error("ERROR GETTING INFORMATION ABOUT PARTNERS");
        logout();
      }
    }
    updatePromoCode();
  };

  const getWithdrawalHistory = async () => {
    try {
      const response = await fetch(`${APIEnd}/withdraw?data=withdrawals`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const data = await response.json()
        console.log("Error Withdrawal", data)
        throw new Error(`Error: ${response.status}`);
      }

      const resdata = await response.json();
      console.log("Withdrawal History: ", data);
      setData(resdata)
    } catch (error) {
      console.error("Error fetching Withdrawal data:", error);
      toast.error("Error fetching data");
    }
  };

  useEffect(()=>{
    getWithdrawalHistory()
  },[])

  return (
    <div >
      <div>
        {" "}
        {/* Promo Code Generator */}
        <h1 style={{ display: "inline" }}>Change Referral Code</h1>
        <div
          style={{
            backgroundColor: "white",
            maxWidth: 400,
            padding: 8,
            borderRadius: 8,
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          <p>
            Current Referral Code{" "}
            <span
              style={{ color: "lightgreen", fontWeight: "bold" }}
            >{`"${partnerInformation?.promo_code}"`}</span>{" "}
          </p>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <input
              value={promoCode}
              type="text"
              onChange={(e) => {
                setPromocode(e.target.value);
              }}
            />
            <button
              onClick={() => handleClick()}
              style={{ backgroundColor: "green" }}
            >
              {" "}
              Update{" "}
            </button>
          </div>
        </div>
      </div>

      <div>
        <h3>Withdrawal Request History</h3>
        <WithdrawalTable data={data}/>
      </div>
    </div>
  );
};

export default DocumentationPage;
