import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import LoginPage from "./pages/Login/LoginPage";
import { useEffect,  } from "react";
import { ToastContainer,  } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./context/AuthContext";
import APIEnd, { API_KEY } from "./configs/apiEndPoint";
import ForgotPassword from "./pages/passwordReset/ForgotPassword";
import EnterOTP from "./pages/passwordReset/EnterOTP";
import ChangePassword from "./pages/passwordReset/ChangePassword";

function App() {
  const { isAuthenticated, login, logout, token, setToken } = useAuth();

  // ONLINE REFRESH FUNCTION
  const calebRefresh = () => {
    const localRefreshToken = localStorage.getItem("refreshToken");

    if (localRefreshToken) {
      const url = `${APIEnd}/login/refresh/`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`,
        },
        body: JSON.stringify({
          refresh: localRefreshToken,
        }),
      })
        .then((response) => {
          if (response.status >= 400) {
            localStorage.clear();
            logout();  
            return;
          }
          return response.json();
        })
        .then((data: any) => {
          // console.log(data);
          setToken(data.access);
          localStorage.setItem("accessToken", data.access);
          login();
        });
    }
  };

  const getToken = async () => {
    setToken(localStorage.getItem("accessToken"));

    const refreshTokenAvailable = localStorage.getItem("refreshToken");

    if (refreshTokenAvailable) {
      login();
    } else {
      logout();
    }
  };

  const centerItem = () => {
    if (window.location.href.includes("user")) {
    }
  };

  useEffect(() => {
    const minutes = 1000 * 60;
    setInterval(calebRefresh, minutes * 7);
    getToken();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/enter-otp" element={<EnterOTP />} />
        <Route path="/change-password" element={<ChangePassword />} />
        
        <Route
          path="/"
          element={isAuthenticated ? <MainLayout /> : <LoginPage />}
        >
          {routes}
        </Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>  
  );
}

export default App;
