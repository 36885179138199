import React, { useEffect, useState } from "react";
import APIEnd, { API_KEY } from "../../configs/apiEndPoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ChangePassword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle the visibility of password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle the visibility of confirm password
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation: Check if new passwords match
    if (newPassword !== confirmPassword) {
      toast.warn("New passwords do not match");
      return;
    } else if (newPassword.length < 8) {
      toast.warn("password must contain at least 8 characters");
      return;
    }

    // Simulate API call to change password
    try {
      const response = await fetch(`${APIEnd}/forgot_password/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
        body: JSON.stringify({
          email: email,
          new_password: newPassword,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to change password");
      }
      const result = await response.json();
      if (response.status === 200) {
        toast.success(result.message);
        navigate("/");
      } else {
        toast.error("Something Went wrong Please try later or contact admin");
        navigate("/");
      }

      // Handle success
      setNewPassword("");
      setConfirmPassword("");
      setEmail("");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setEmail(localStorage.getItem("resetEmail"));
    console.log(email);
  }, [email]);

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <img
          src={logo}
          alt="Avisa"
          style={{
            display: "flex",
            justifySelf: "center",
            alignSelf: "center",
            width: 152,
            height: 150,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: "8px" }} />
          <p style={{ color: "#345" }}>Enter your new Password</p>
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
            style={styles.input}
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={email ? true : false}
          />
        </div>

        {/*  */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            autoFocus
            style={{
              flex: 1,
              paddingRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />

          <button
            type="button"
            style={{
              position: "relative",
              background: "transparent",
              border: "1px solid #bbbfbe",
              top: -5,
              padding: 10,
              borderRadius: 0,
            }}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              color="grey"
            />
          </button>
        </div>
        {/*  */}


        {/* Confirm Password */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            style={{
              flex: 1,
              paddingRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />

          <button
            type="button"
            style={{
              position: "relative",
              background: "transparent",
              border: "1px solid #bbbfbe",
              top: -5,
              padding: 10,
              borderRadius: 0,
            }}
            onClick={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
          >
            <FontAwesomeIcon
              icon={showConfirmPassword ? faEyeSlash : faEye}
              color="grey"
            />
          </button>
        </div>
        {/* End confirm password */}

        <button type="submit" style={styles.button}>
          Change Password
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#3a663a",
    padding: "20px",
  },
  form: {
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  formGroup: {
    marginBottom: "15px",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "5px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    marginTop: "20px",
    width: "100%",
    padding: "10px",
    backgroundColor: "#007bff",
    color: "#Green",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  error: {
    color: "red",
    fontSize: "0.875rem",
  },
  message: {
    color: "green",
    fontSize: "1rem",
    marginBottom: "20px",
  },
};

export default ChangePassword;
